


/* Default LTR style */
body {
  font-family: 'Montserrat', sans-serif !important;
  background: white;
}
* {
  font-family: 'Montserrat', sans-serif !important;
}

/* When RTL class is applied */
body.rtl {
  font-family: 'Open Sans', sans-serif !important;
}
body.rtl * {
  font-family: 'Open Sans', sans-serif !important;
}

:host(body.rtl) {
  transform-origin: right center;
}

/* Default styles */
:root {
  --ion-color-primary: #00254B;
  --color: #00254B;
  --ion-font-family: 'Montserrat', sans-serif !important;
  background: #FFFFFF;
  background-color: #FFFFFF;
  --background: #FFFFFF;
  --background-color: #FFFFFF;
  --ion-item-background: #FFFFFF;
}

/* ion-content default styles */
ion-content {
  --ion-font-family: 'Montserrat', sans-serif !important;
  background: #FFFFFF;
  background-color: #FFFFFF;
  --background: #FFFFFF;
  --background-color: #FFFFFF;
}

/* ion-content when RTL is active */
body.rtl ion-content {
  --ion-font-family: 'Open Sans', sans-serif !important;
}

img,svg {max-width: 100%;height: auto;}
.clickable {cursor: pointer;}

@media (prefers-color-scheme: dark){
:root{
  --color:#00254B;
  --ion-overlay-background-color : white;
  --ion-background-color-rgb: 255, 255, 255;
  --ion-color-primary:#00254B;
  --ion-background-color: #ffffff;
    --ion-background-color-rgb: 255, 255, 255;
    --ion-text-color: #00254B;
    --ion-text-color-rgb: 0, 37, 75;
    --ion-color-step-50: #00254B;
    --ion-color-step-100: #00254B;
    --ion-color-step-150: #00254B;
    --ion-color-step-200: #00254B;
    --ion-color-step-250: #00254B;
    --ion-color-step-300: #00254B;
    --ion-color-step-350: #00254B;
    --ion-color-step-400: #00254B;
    --ion-color-step-450: #00254B;
    --ion-color-step-500: #00254B;
    --ion-color-step-550: #00254B;
    --ion-color-step-600: #00254B;
    --ion-color-step-650: #00254B;
    --ion-color-step-700: #00254B;
    --ion-color-step-750: #00254B;
    --ion-color-step-800: #00254B;
    --ion-color-step-850: #00254B;
    --ion-color-step-900: #00254B;
    --ion-color-step-950: #00254B;
    --ion-item-background: #ffffff;
    --ion-card-background: #ffffff;

}

.list-ios {background: #FFFFFF !important;}
ion-card {background: #FFFFFF !important;}
ion-alert {background: #FFFFFF !important;}

ios ion-modal {
  --ion-background-color: #FFFFFF !important;
  --ion-toolbar-background: #FFFFFF!important;
  --ion-toolbar-border-color: #FFFFFF!important;
}
ion-label {color: #00254B!important;}
.bottomTaps ion-label {color: #fff!important;}

ion-modal > .ion-page {background: white;border-radius: 16px;}
.custom_product_checkbox ion-checkbox::part(container) {background:#ffffff}
.button-solid{--color:#fff}

.ios body,.md body {
  --ion-color-primary: #00254B;
  --ion-color-primary-rgb: 0, 37, 75;

  background: #FFFFFF !important;
    --ion-background-color: #ffffff;
    --ion-background-color-rgb: 255, 255, 255;
    --ion-text-color: #00254B;
    --ion-text-color-rgb: 0, 37, 75;
    --ion-color-step-50: #00254B;
    --ion-color-step-100: #00254B;
    --ion-color-step-150: #00254B;
    --ion-color-step-200: #00254B;
    --ion-color-step-250: #00254B;
    --ion-color-step-300: #00254B;
    --ion-color-step-350: #00254B;
    --ion-color-step-400: #00254B;
    --ion-color-step-450: #00254B;
    --ion-color-step-500: #00254B;
    --ion-color-step-550: #00254B;
    --ion-color-step-600: #00254B;
    --ion-color-step-650: #00254B;
    --ion-color-step-700: #00254B;
    --ion-color-step-750: #00254B;
    --ion-color-step-800: #00254B;
    --ion-color-step-850: #00254B;
    --ion-color-step-900: #00254B;
    --ion-color-step-950: #00254B;
    --ion-item-background: #ffffff;
    --ion-card-background: #ffffff;
}

}

ion-popover-view {/*padding: 10vh 2vw;*/}

/*
@media (prefers-color-scheme: dark) {
  :root {
    --background: black;
    --background-color: black;
    background: black;
    background-color: black;
    --color:white;
    color: white!important;
  }
  ion-content{
    --background: black;
    --background-color: black;
    background: black;
    background-color: black;
    color: white!important;
    --color:white;
    color: white!important;
  }
  body {background: black !important; color: white!important;}
  body * {background: black !important; color: white!important;}
}*/


.no-text-cut {  white-space: normal !important;  overflow: visible !important;  text-overflow: clip !important; line-height: 1.5!important; font-size: 12px!important; padding: 0; margin: 18px 0 18px 6px;}
body.rtl .no-text-cut {margin: 18px 6px 18px 0;}
.ios .rgs_checkbox {margin-top:20px}


.md ion-button{--border-radius: 10px;}

input,textarea,select{
  font-family: 'Montserrat', sans-serif!important;
  background: white;
  background-color: #FFFFFF;
  --background:#FFFFFF;
  --background-color: #FFFFFF;
  border-radius: 6px!important;
/*border: 1px solid #00254B!important;*/
color: #00254B !important;
font-family: Montserrat!important;
font-size: 16px!important;
font-style: normal!important;
font-weight: 400!important;
line-height: normal!important;
}

input[type="file"] {
  display: none;
}

input[type="file"] + label {
  padding: 10px;
  background-image: url(../icons/camera-blue.svg);
  width:36px; height:36px;
  display: inline-block;
  cursor: pointer;
}

ion-input {/*border: 1px solid #00254B!important;  padding: 0px 15px !important;*/}
ion-label{  font-family: Montserrat!important; }

.w-100{width: 100%;}





.d-none {
  display: none!important;
}

ion-toolbar {
  --border-width: 0;
  --box-shadow: none!important;
}
ion-toolbar {
  --ion-toolbar-background: initial;
}

app-header-container {
  position: static;
}
.ion-page{
  padding-bottom: 35px;
}



/*

@media(orientation: portrait) {
  ion-content {
   padding-top: constant(safe-area-inset-top);
   padding-top: env(safe-area-inset-top);
}
}
*/

.input_container {border: 1px solid #00254B!important;border-radius: 6px;}
.border {border: 1px solid #00254B!important;  padding: 0px 15px !important;}
ion-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 15px;
  --padding-start: 0;
  width: 100%;
}
h1{color: #00254B;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-top: 0;
}

h2.popupTitle{color: #00254B;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
  margin-top: 0px;
}

h2.subTitle{color: #00254B;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

h2.subTitle img{margin-right: 10px;margin-left: 0;}
body.rtl h2.subTitle img{margin-left: 10px; margin-right: 0;}

.header-background{display: none;}
.no-margin {
  margin: 0 !important;
}

:host {--width: 100%;--color:#00254B }
.header-auth ion-toolbar:last-of-type {--border-width:0;background:transparent;--ion-toolbar-background:transparent}
ion-content::part(background) {
  background: url(./header-bg.png); background-repeat: repeat-x;
  color: #00254B;
}

ion-menu ion-content::part(background) {background: transparent;}
ion-modal > .ion-page {
  overflow-y: auto;
  padding-bottom: 0;
  --height: auto;
  --background: white;
  background: white;border-radius: 16px;
  /*min-height: 50vh;*/
}

.header{ background-color: #FFFFFF; background-image: url(./header-bg.png); background-repeat: repeat-x;direction: ltr;}

.header ion-toolbar:last-of-type {--border-width:0;background:transparent;--ion-toolbar-background:transparent}

.item-native,.bg-transparent {
  background: transparent!important;

}

ion-item {
  --ion-item-background: transparent;
  padding-inline-start: 0;
  padding: 0 !important;
}
ion-item.no-padding { --padding-start:0;}
ion-item.no-padding  .item-native {padding-left: 0;}
body.rtl ion-item.no-padding  .item-native {padding-right: 0;}
ion-item.no-padding::part(native) {padding-left: 0;}
body.rtl ion-item.no-padding::part(native) {padding-right: 0;}

.padding-0 {
  padding: 0 !important;
}

.margin-0 {
  margin: 0 !important;
}

.menu-content,.menu-inner,:host(.menu-side-end) .menu-inner {--width: 100%;width: 100%;}
body.rtl  .menu_close_icons {    flex-direction: column-reverse;}

ion-menu::part(container) {
    --width: 100%;width: 100%;
    max-width: 100%!important;
  }


.header .toolbar-title-default{/*background: #FFF; box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);padding:5px 10px;*/}

.bottomTaps {background: #045F9B;  box-shadow: 0px 4px 30px 0px rgba(7, 111, 179, 0.25);/*padding-top: 1.5%;padding-bottom: 0.5%;*/border:none;height: 80px;padding: 0;}
.bottomTaps .tab{background-color: #045F9B;color: white;padding:0}
.bottomTaps .tab-selected {color:#0AF5FA}
.bottomTaps .tab-selected * {color:#0AF5FA}
.bottomTaps .tab-selected img{filter: brightness(0) saturate(100%) invert(90%) sepia(88%) saturate(3604%) hue-rotate(104deg) brightness(107%) contrast(95%)}
.bottomTaps .title-label {font-size: 10px;margin-top:5px}
.btn-barcode {padding: 0; background-color: white !important; border-radius: 100px;  --background-activated: white; --width: 60px;  width: 60px;  max-width: 60px;  height: 60px;    margin-top: -5px;}
.fab-horizontal-center {bottom: 3.1%;}
.tab-Barcode {opacity: 0;}
.plt-ios .bottomTaps {padding-bottom: 15px;}

ion-header ion-menu-button,ion-header ion-buttons{color: #045F9B;--color: #045F9B;}
.btn-like-link-wrapper{font-size: 16px;}
.btn-like-link {
  font-size: 16px; /* Adjust as needed */
  border-bottom: 1px solid #000;
  padding: 0;
}

.primary-color {color: #00254B;}
ion-button{text-transform: none !important;}
ion-button.btn-like-link {
  --background: tranbsparent;
  --background-hover: tranbsparent;
  --background-activated: tranbsparent;
  --background-focused: tranbsparent;
  --font-size:14px;
  font-size:14px;
  --color: #000;

  --border-radius: 0;
  --border-color: #000;
  --border-style: solid;
  --border-width: 0;
  --border-bottom-width: 1px;
  --padding-top: 0;
  --padding-bottom: 0;
  --box-shadow: 0 ;

  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  min-height: 0 !important;
  height: auto;
}
ion-button.btn-link {
  --background: tranbsparent;
  --background-hover: tranbsparent;
  --background-activated: tranbsparent;
  --background-focused: tranbsparent;
  --font-size:12px;
  font-size:12px;
  --color: #000;

  --border-radius: 0;
  --border-color: #000;
  --border-style: solid;
  --border-width: 0;
  --border-bottom-width: 0px;
  --padding-top: 0;
  --padding-bottom: 0;
  --box-shadow: 0 ;

  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
  --padding-start: 0 !important;
  --padding-end: 0 !important;

}
ion-button.btn-resetPassword {
  --background: tranbsparent;
  --background-hover: tranbsparent;
  --background-activated: tranbsparent;
  --background-focused: tranbsparent;
  --font-size:14px;
  --color: #000;

  --border-radius: 0;
  --border-color: #000;
  --border-style: solid;
  --border-width: 0;
  --border-bottom-width: 0px;
  --padding-top: 0;
  --padding-bottom: 0;
  --box-shadow: 0 ;

  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  min-height: 0 !important;
  text-decoration: underline;
  font-weight: 400;
}
ion-button.btn-clear {
  --background: tranbsparent;
  --background-hover: tranbsparent;
  --background-activated: tranbsparent;
  --background-focused: tranbsparent;
  --font-size:16px;
  --color: #000;
  color: #000;

  --border-radius: 0;
  --border-color: #000;
  --border-style: solid;
  --border-width: 0;
  --border-bottom-width: 1px;
  --padding-top: 0;
  --padding-bottom: 0;
  --box-shadow: 0 ;

  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  min-height: 60px !important;

}
ion-button.btn-add {
  --background: tranbsparent;
  --background-hover: tranbsparent;
  --background-activated: tranbsparent;
  --background-focused: tranbsparent;
  --font-size:13px;
  font-size:13px;
  --color: #00254B;

  --border-radius: 0;
  --border-color: #00254B;
  --border-style: solid;
  --border-width: 0;
  --border-bottom-width: 1px;
  --padding-top: 0;
  --padding-bottom: 0;
  --box-shadow: 0 ;

  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  min-height: 0 !important;
  font-weight: 600;
  /*margin: 10px 15px;*/
}
ion-button.btn-add img{margin-left: 5px;}
body.rtl ion-button.btn-add img{margin-right: 5px;}

ion-button.btn-sky-blue{
  --background: #0AF5FA;
  --background-hover: #0AF5FA;
  --background-activated: #0AF5FA;
  --background-focused: #0AF5FA;
  --font-size:16px;
  --color: #000;
  color: #000;
  --border-radius:100px;
  --padding-start: 32px !important;
  --padding-end: 32px !important;
  --padding-top: 15px !important;
  --padding-bottom: 15px !important;
  height:auto;
  --box-shadow: none;
}

ion-button.btn-red{
  --background: #EB6767;
  --background-hover: #EB6767;
  --background-activated: #EB6767;
  --background-focused: #EB6767;
  --font-size:16px;
  --color: #FFFFFF;
  color: #FFFFFF;
  --border-radius:100px;
  --padding-start: 32px !important;
  --padding-end: 32px !important;
  --padding-top: 15px !important;
  --padding-bottom: 15px !important;
  height:auto
}
ion-button.btn-signup{
  --background: #0AF5FA;
  --background-hover: #0AF5FA;
  --background-activated: #0AF5FA;
  --background-focused: #0AF5FA;
  --font-size:16px;
  font-size:16px;
  --color: #000;
  color: #000;
  --border-radius:100px;
  --padding-start: 32px !important;
  --padding-end: 32px !important;
  --padding-top: 15px !important;
  --padding-bottom: 15px !important;
  height:auto;
  margin-right: auto;
  margin-left: auto;
}

ion-button.btn-login{
  --background: #00254B;
  --background-hover: #00254B;
  --background-activated: #00254B;
  --background-focused: #00254B;
  --font-size:16px;
  font-size:16px;
  --color: #FFF;
  --border-radius:100px;
  --padding-start: 32px !important;
  --padding-end: 32px !important;
  --padding-top: 15px !important;
  --padding-bottom: 15px !important;
  height:auto;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 0
}

ion-button.btn-sky-blue.small{width: 160px;}

ion-button.btn-dark-blue{
  --background: #00254B;
  --background-hover: #00254B;
  --background-activated: #00254B;
  --background-focused: #00254B;
  --font-size:16px;
  --color: #FFF;
  --border-radius:100px;
  --padding-start: 32px !important;
  --padding-end: 32px !important;
  --padding-top: 15px !important;
  --padding-bottom: 15px !important;
  height:auto
}



ion-button.btn-white{
  --background: #FFFFFF;
  --background-hover: #FFFFFF;
  --background-activated: #FFFFFF;
  --background-focused: #FFFFFF;
  --font-size:16px;
  --color: #00254B;
  --border-radius:100px;
  --padding-start: 32px !important;
  --padding-end: 32px !important;
  --padding-top: 15px !important;
  --padding-bottom: 15px !important;
  height:auto;
  --border-width: 1px ;
  --border-color: #00254B;
  --border-style: solid;
  --box-shadow: none;
}

ion-button.btn-filter {

  --background: #F5F5F5;
  --background-hover: #F5F5F5;
  --background-activated: #F5F5F5;
  --background-focused: #F5F5F5;
  --font-size:12px;
  --color: #00254B;
  --border-radius:100px;
  --padding-start: 13px !important;
  --padding-end: 13px !important;
  --padding-top: 6px !important;
  --padding-bottom: 6px !important;
  --box-shadow: none;
  font-size: 12px;
}

ion-button.btn-filter ion-img{margin-right: 6px;margin-left: 0;}
body.rtl ion-button.btn-filter ion-img{margin-left: 6px;margin-right: 0;}

.service_logs_three_dots {--min-height: auto;--padding-start: 0; --inner-padding-end: 0;    position: absolute;    right: 0;z-index: 123;top: 15px;}
body.rtl .service_logs_three_dots{left: 0; right: auto;}

.leaflet-control a,.leaflet-control-custom  {max-width: 100%;width: 9vw!important;height: 9vw!important;}
ion-button ,button,.leaflet-control a,.fix_accessibbility{max-width: 100%;min-width: 9vw!important;min-height: 9vw!important;}
ion-button * {max-width: 100%;}
.popover-viewport,ion-popover{--width: auto;min-width: 70vw;--box-shadow:box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);}
.popover-viewport ion-select.custom-input { border-radius: 6px; border: 1px solid #00254B;  }

.center-items ion-item {  text-align: center;}
.text-right {text-align: right;}
.text-left {text-align: left;}
.text-center {text-align: center;}
body.rtl .text-right {text-align: left;}
body.rtl .text-left {text-align: right;}

.grid{display: flex; width: 100%; align-items: center; justify-content: space-around;flex-wrap: wrap;}
.grid .col{padding: 0 10px;    flex: 1 0 0%;width: 100%;  max-width: 100%;text-align: center;}
.btn-close,.btn-save{width: 130px; min-height: 40px;max-width: 100%; font-size: 16px;padding:12px;border:1px solid #00254B;border-radius: 100px;margin: 15px 0;}
.btn-save{background-color: #00254B;color: #FFFFFF;}
.btn-close {color: #00254B;background-color: #FFFFFF;}

.menu_item {color: #00254B !important;  font-family: Montserrat!important;  font-size: 18px!important;  font-style: normal;  font-weight: 500;  line-height: normal;}

.btn-image {margin-right: 10px;}
body.rtl .btn-image {margin-right: 0;margin-left: 10px;}

.avatar {width: 30px; height: 30px;border-radius: 100%;  display: inline-block;  text-align: center;  padding: 8px 3px 0 2px;text-transform: uppercase;font-size: 11px!important;background-color: #00254B!important;
  color: white!important;
  border: 1px solid white!important;
}

ion-popover{/*--width:80%*/}
ion-popover ion-button {/*max-width: 200px;*/}
ion-select{--width:80%;    flex-direction: row;}

.center-label {
  display: flex;
  align-items: center;
}

ion-select.selectedTeamId{width:100%;border-radius: 10px;  background: #FFF,;color: #00254B;  font-family: Montserrat;  font-size: 16px;  font-style: normal;  font-weight: 400;  line-height: normal;
  --background: url('../icons/arrow-up-right-from-square-blue.svg') no-repeat right center / contain;
 }
ion-select::part(select-icon) {
  opacity: 0;
}


.hr {width:100%; /*background:url('../icons/hr.svg');background-repeat: repeat-x;**/height: 3px;fill: rgba(28, 62, 88, 0.30);background-image: linear-gradient(to right, #1C3E584D 30%, rgba(255,255,255,0) 0%);
  background-position: bottom;  background-size: 6px 3px;  background-repeat: repeat-x;}

.selectedTeamId {
  width: 100%;
  border-radius: 10px;
  background: #FFF url('../icons/chevron-down-solid.svg') no-repeat right center / contain;
  color: #00254B;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  border-radius: 10px;
  background-size: 15px 15px;
  background-position-x: 95%;
  min-height: 40px;
}

.selectedTeamId ion-select {
  --background: transparent;
  --color: #00254B;
  --padding-end: 30px;
  --padding-start: 30px;
  width:100%;
  max-width: 100%;
  font-family: Montserrat;
  font-size: 15px;
  background: transparent;
  --background: transparent;
  border: none;
  --border: none;
  padding-right: 50px;
}

.selectedTeamId ion-select::part(text) {
  opacity: 1;
}

.selectedTeamId ion-select::part(icon) {
  display: none !important;
 }

 ion-menu-button::part(button) ion-icon {width: 1.2em;}


#map {
  display: block;
  position: relative;
  width: 100%;
  height: 400px;
}

.leaflet-container img {
  max-height: none;
  max-width: none;
}

.theTabs{position:unset!important;height:auto!important;contain:unset!important}
ion-input.custom-input{  color: #00254B; opacity: 1 !important;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 15px;}
ion-input.custom-input .native-input  {
  opacity: 1 !important; padding: 10px 15px;
  border: 1px solid #FFFFFF;

}
ion-textarea.custom-input  textarea {opacity: 1 !important;
  padding: 10px 15px !important ;}

ion-input.custom-input-bordred {
  color: #00254B; opacity: 1 !important;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 15px!important;
  border-radius: 6px;
  border: 1px solid #00254B;

}

ion-label.custom-label {
  opacity: 1 !important;
}



ion-select.custom-input{  color: #00254B; opacity: 1 !important;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 15px;
  --placeholder-opacity: 1;
  width: 100%;
  text-align: left;
}
body.rtl ion-select.custom-input{  text-align: right;}


ion-select .select-placeholder {
    opacity: 1 !important;

}

ion-select {
  position: relative;
  background: transparent url('../icons/chevron-down-solid.svg') no-repeat right center / contain;
  border-radius: 6px;
  background-size: 15px 15px;
  background-position-x: 95%;
  min-height: 40px;
}
body.rtl  ion-select {

  background-position-x: 5%;
}

ion-select.select-disabled{background: none;}


ion-select::part(select-icon) {
  opacity: 0;
}

ion-select::part(text) {
  opacity: 1;
}

 ion-select::part(icon) {
  display: none !important;
 }


 ion-input.input-enabled .native-input {
  border-radius: 6px;
  border: 1px solid #00254B;
}
ion-select.input-enabled{
  border-radius: 6px;
  border: 1px solid #00254B;
}

ion-textarea {opacity: 1;}
ion-textarea.input-enabled{
  padding: 0!important;
  border-radius: 6px;
  border: 1px solid #00254B;
}

ion-input,ion-input input::placeholder, ion-textarea,ion-textarea textarea::placeholder,ion-select,ion-select select::placeholder  {
  --placeholder-color: #00254B;
  --placeholder-opacity: 1;
  color: #00254B;
  opacity: 1;

}

ion-item {
  --inner-padding-end: 0;
  display: flex !important;
  justify-content: space-between !important;
}


swiper-container {
  --swiper-pagination-bullet-inactive-color: #797777;
  --swiper-pagination-color: #00254B;
  --swiper-theme-color:  #797777;
  --swiper-pagination-bullet-active-bg-color:  #797777;
  --swiper-pagination-bullet-active-color:#797777;
  --swiper-pagination-bullet-active-background: var(--ion-color-primary, #797777);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
  --swiper-min-height:220px;
  min-height:220px;


}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent black */
  z-index: 9999; /* Ensure it's above all other elements */
}


ion-content::part(background).global-bg{background: url(./header-bg.png);  background-repeat: repeat-x;  color: #00254B;}


swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
  padding-bottom: 20px;

}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.slider_image {width: 249px;height: 168px; object-fit: cover;}


ion-card{
  line-height: 0;
  border-radius: 10px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 16px;

}


.productsboxes .item-native{padding-inline-start:0}
.products_sliding { background-color: #1E96D4;/*box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);*/ border-radius: 10px;color: #FFFFFF;  padding: 10px;}
 .products_box {color: #FFFFFF;line-height: 1.3;font-size: 14px;font-weight: 500;}
 .products_box_border{width: 1px;height: 100%;;  background-color: #FFFFFF;}
 .products_box .products_box_item{margin-bottom: 5px;;}
 .products_option {background: #C3E9F9;}
 .ion-no-padding {
  padding-inline-start:0;
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
}
.products_sliding .custom_product_checkbox {--min-height: auto;--padding-start: 0; --inner-padding-end: 0;  position: absolute; left: 10px;z-index: 123;top: -10px;}
body.rtl .products_sliding .custom_product_checkbox{left: auto; right: 10px;}

.products_sliding .thre_dots{--min-height: auto;--padding-start: 0; --inner-padding-end: 0;  position: absolute; right: 0px;z-index: 123;top: 0;}
body.rtl .products_sliding .thre_dots{left: 0px; right: auto;}



/*
.service_sliding { background-color: #1E96D4;box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25); border-radius: 10px;color: #FFFFFF;  padding: 10px;}
 .service_box {color: #FFFFFF;line-height: 1.3;font-size: 14px;font-weight: 500;}
 .service_box_border{width: 1px;height: 100%;;  background-color: #FFFFFF;}
 .service_box .products_box_item{margin-bottom: 5px;;}
 */
 .service_log_rotate {transform-origin: 0 0;  transform: rotate(-90deg); position: absolute; bottom: -30px;width: 100%;background: #045F9B;color: #FFF;font-size: 12px;font-weight: 400;padding:15px; text-overflow: ellipsis;
  overflow: hidden;white-space: nowrap; }
 .service_option {background: #C3E9F9;}

.service_log_status_box {text-align: center;}
.service_log_status {display: inline-flex;padding: 8px 12px;justify-content: center; align-items: center;  gap: 10px;border-radius: 10px; color: #00254B;}

.custom_service_log_status_container {width: 100%;text-align: center;border-radius: 10px}
/*
.custom_service_log_status_container::after{content: '';width: 15px;height: 15px;position: absolute;right:15px ;background-image: url(../icons/chevron-down-regular.svg);}
.custom_service_log_status::part(icon){display: none!important;}*/


.ToDo {background:#DFE4F8;}
.inProgress {background: #C3FBFF;}
.Done {background: #C5FFC7;}
.recorOnly {background: #FFEDCA;}
.Assignto {background: #F5F5F5;}
.service_log {color: #00254B;font-size: 16px;}
.bold{font-weight: 600!important;}
.bold500{font-weight: 500!important;}
.cart{border-radius: 6px; padding: 6px; border: 1px solid #B7B5B5;height: 132px; overflow: auto;}
ion-col .service_box_item .avatar{margin-left: -6px;}
body.rtl ion-col .service_box_item .avatar{margin-right: -6px;}
.service_box_dueDate {display: flex;flex-direction: row; justify-content: flex-end; align-items: center;margin: 0 15px;}
.service_box_dueDate span {border-radius: 40px;  padding: 5px 10px;color: #00254B; display: flex;  align-items: center;  color: #00254B;  text-align: center;  font-size: 12px;  font-weight: 500;}
  .service_box_dueDate span img{margin-right: 6px;}
  body.rtl .service_box_dueDate span img{margin-left: 6px;margin-right: 0;}

.ion-no-xmargin {
  margin-inline-start:0;
  margin-inline-end:0;
  --margin-start: 0;
  --margin-end: 0;
  --inner-margin-start: 0;
  --inner-margin-end: 0;
  margin-right: 0;
  margin-left: 0;
}

.user-bg {border-radius: 10px;padding: 8px 10px;    display: inline-block;background-color:#f5f5f5!important}


.absolute-position {  position: absolute;  top: -5px;   right: 0px;z-index: 11; }

.lightbox {height: 85vh;max-width: 100%;  width: 95vw!important; overflow: auto;right: 0 !important;  left: 2.5vw !important;top: 2.5vh !important;}
body.rtl .lightbox {right: 2.5vw !important;  left: 0 !important;}
.lb-dataContainer {position: fixed;bottom: 5vh;}

.searchbar-input.sc-ion-searchbar-md {padding-inline-end:25px;padding-inline-start:25px;}
.search_box * {border:none!important;box-shadow: none!important;    max-height: 40px;  background: transparent !important;font-size: 14px!important;}
.search_box  { border-radius: 10px; background: #FFF;  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);padding:0!important; }
.search_box ion-searchbar{padding-inline-start: 0px;height: 50px !important;border-radius: 10px;background-image: url(../icons/search.svg)!important; background-repeat: no-repeat!important; background-position: 95% 10px!important;}
body.rtl .search_box ion-searchbar{background-position: 5% 10px!important;}
.search_box ion-searchbar.searchbar-has-value {background-image: none!important;}
.searchbar-search-icon {display: none;}
.search_box ion-searchbar .searchbar-clear-button {right: 0%!important; top: -2px!important;}
body.rtl .search_box ion-searchbar .searchbar-clear-button {left: 0%!important; right: auto!important;}

/*
ion-item-options {transform: translate3d(-45px, 0, 0);-webkit-transform: translate3d(-45px, 0, 0); -webkit-animation-duration: 1000ms;
  animation-duraton: 1000ms;}
.item-sliding-active-slide ion-item-options {transform: translate3d(0px, 0, 0);-webkit-transform: translate3d(0, 0, 0);}

@-webkit-keyframes slidingAnimation {
  0% {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(-70px, 0, 0);
    -webkit-transform: translate3d(-70px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}

.itemSlidingAnimation {
  -webkit-animation: slidingAnimation;
  animation: slidingAnimation;
  -webkit-animation-duration: 1000ms;
  animation-duraton: 1000ms;
}

*/


/*
.start-closing {
  animation: customCloseAnimation 0.5s ease;
}


@keyframes customCloseAnimation {
  0% {
      transform: translateX(-45px);
  }
  100% {
      transform: translateX(0);
  }
}
*/



/* Animation for opening */
.open-animation {
  animation-name: slideOpen;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes slideOpen {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

/* Animation for closing */
.close-animation {
  animation-name: slideClose;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes slideClose {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}


.leaflet-touch .leaflet-control-attribution a,.leaflet-touch .leaflet-control-attribution span {
  display: none!important;
}

.home2boxes {display: flex;flex-direction: column;border-radius: 10px;box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);padding: 8px;color: #FFFFFF;font-size: 16px;font-weight: 500;text-align: center;align-items: center;  justify-content: center;height: 168px;}
.homeboxes a {text-decoration: none;}
.home2boxes .home2boxes-img{margin-bottom:10px ;padding:10px 20px;border-bottom:1.5px solid #FFFFFF;}
.home2boxes .lastUpdate{font-size: 12px;font-weight: 400;margin-top: 10px;    min-height: 15px;}
.bg-blue-sky {background: #1E96D4;}
.bg-blue-dark {background: #04619D;}
.home2boxes .rounded{border-radius: 100px;background-color: #FFFFFF;filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));color: #00254B;font-size: 15px;font-weight: 400;line-height: 1.3; padding: 5px 0px;
  width: 30px;  height: 30px;  text-align: center;  position: absolute;  top: 15px;  right: 15px;}

  .header .rounded{border-radius: 100px;    background-color: #EB6767; color: #FFFFFF;
    font-size: 12px;    font-weight: 400;    line-height: 1;    width: 20px;    height: 20px;    text-align: center;    position: absolute;    top: 0px;    left: 21px;    z-index: 123;    display: flex;    align-items: center;    justify-content: center;}

.reportsHome {border-radius: 10px;box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);padding: 8px;color: #FFFFFF;font-size: 16px;font-weight: 500;text-align: center;align-items: center;  justify-content: center;}
.reportsHome img{padding:10px 20px;border-bottom:1.5px solid #FFFFFF;}
.reportsHome .title{font-size: 16px;font-weight: 500;}
.reportsHome .counter{font-size: 25px;font-weight: 600;}

.artilceBox {padding:0; margin-bottom: 18px;padding-bottom: 18px;border-bottom: 3px dotted rgba(28, 62, 88, 0.30);;}
.artilceBox:last-child {/*border-bottom: none;*/}
.artilceBox .artilce_thumb{border-radius: 10px;background: /*url(<path-to-image>),*/ lightgray 50% / cover no-repeat, #D9D9D9;height: 182px;    object-fit: cover;margin-bottom: 15px;}
.artilceBox .artilce_title{color: #00254B; font-size: 18px; font-style: normal;  font-weight: 500;  line-height: normal;margin-bottom: 5px;}
.artilceBox .artilce_short{color: #00254B; font-size: 16px;  font-style: normal;  font-weight: 400;  line-height: 150%;}
.artilceBox .artilce_readMore{color: #00254B;font-size: 16px;font-weight: 400; line-height: 140%;  text-decoration-line: underline;    display: flex;  align-items: center;  justify-content: flex-end;margin-top: 10px;}
.artilceBox .artilce_readMore img{margin-left: 5px;}
body.rtl .artilceBox .artilce_readMore img{margin-right: 5px;margin-left: 0;transform: rotate(180deg);}

.artilce_single_thumb {border-radius: 10px;height: 182px;    object-fit: fill;margin-bottom: 5px;}
.artilce_single_writer {color: #000;font-size: 14px;font-weight: 400;}
.artilce_single_title {color: #00254B;font-size: 18px; font-style: normal;  font-weight: 500;  line-height: normal;margin-top: 15px;margin-bottom: 15px;}
.artilce_single_content {color: #00254B;   font-size: 16px;  font-style: normal;  font-weight: 400;line-height: 150%;margin-bottom: 25px;}
.artilce_single_content h2,.artilce_single_content h3,.artilce_single_content h4,.artilce_single_content h5,.artilce_single_content h6{color: #00254B; font-size: 16px; font-style: normal;  font-weight: 500;  line-height: normal;margin-top: 15px;margin-bottom: 15px;}
.artilce_single_content img{border-radius: 10px;height: 182px;    object-fit: fill;margin-bottom: 5px;}
.artilce_single_content iframe{max-width: 100%;}


.splash-screen {position: fixed;  top: 0;  left: 0;  right: 0;  bottom: 0;   z-index: 9999;  display: flex;  align-items: center;  justify-content: center;}
.breathing{ -webkit-animation: breathing 7s ease-out infinite normal;   animation: breathing 7s ease-out infinite normal;}
.wave {overflow: hidden;height: 100vh;	width:100%; background: linear-gradient(0deg, #000851 0%, #1CB5E0 100%);  position: fixed;  left: 0;  right: 0;  bottom: 0;}

@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}


@keyframes waves {
  0%, 100% {
    clip-path: polygon(0 8%, 7% 6%, 14% 5%, 21% 5%, 28% 6%, 34% 8%, 40% 12%, 46% 17%, 50% 20%, 54% 23%, 60% 25%, 66% 26%, 70% 26%, 77% 25%, 83% 23%, 89% 21%, 95% 19%, 100% 17%, 100% 100%, 0% 100%);
  }

  50% {
    clip-path: polygon(0 15%, 9% 21%, 14% 23%, 18% 25%, 21% 26%, 30% 28%, 32% 28%, 40% 27%, 46% 26%, 52% 23%, 57% 19%, 62% 15%, 68% 11%, 73% 8%, 79% 6%, 86% 5%, 93% 5%, 100% 6%, 100% 100%, 0% 100%);
  }
}

@keyframes heightChange {
  0% {
    height: 2vh;
  }
  100% {
    height: 150vh;
  }
}


@keyframes lineInserted {
  from {
    height: 0;
  }
  to {
    height: 20px; /* your line height here */
  }
}

@keyframes stretch {
  0% {
    bottom: -130%;
  }

  100% {
    bottom: -1px;
  }
}


.bubble {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  position: absolute;
  background-color: white;
  bottom: -30px;
  opacity: 0.2;
  animation: bubble 15s ease-in-out infinite,
    sideWays 4s ease-in-out infinite alternate;
}

@keyframes bubble {
  0% {
    transform: translateY(0%);
    opacity: 0.06;
  }
  100% {
    transform: translateY(-120vh);
  }
}

@keyframes sideWays {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 200px;
  }
}

.bubble--1 {
  left: 10%;
  animation-delay: 0.5s;
  animation-duration: 16s;
  opacity: 0.2;
}

.bubble--2 {
  width: 15px;
  height: 15px;
  left: 40%;
  animation-delay: 1s;
  animation-duration: 10s;
  opacity: 0.1;
}

.bubble--3 {
  width: 10px;
  height: 10px;
  left: 30%;
  animation-delay: 5s;
  animation-duration: 20s;
  opacity: 0.3;
}

.bubble--4 {
  width: 25px;
  height: 25px;
  left: 40%;
  animation-delay: 8s;
  animation-duration: 17s;
  opacity: 0.2;
}

.bubble--5 {
  width: 30px;
  height: 30px;
  left: 60%;
  animation-delay: 10s;
  animation-duration: 15s;
  opacity: 0.1;
}

.bubble--6 {
  width: 10px;
  height: 10px;
  left: 80%;
  animation-delay: 3s;
  animation-duration: 30s;
  opacity: 0.4;
}

.bubble--7 {
  width: 15px;
  height: 15px;
  left: 90%;
  animation-delay: -7s;
  animation-duration: 25s;
  opacity: 0.3;
}

.bubble--9 {
  width: 20px;
  height: 20px;
  left: 50%;
  bottom: 30px;
  animation-delay: -5s;
  animation-duration: 19s;
  opacity: 0.2;
}

.bubble--10 {
  width: 40px;
  height: 40px;
  left: 30%;
  bottom: 30px;
  animation-delay: -21s;
  animation-duration: 16s;
  opacity: 0.3;
}

.bubble--11 {
  width: 30px;
  height: 30px;
  left: 60%;
  bottom: 30px;
  animation-delay: -13.75s;
  animation-duration: 20s;
  opacity: 0.3;
}

.bubble--11 {
  width: 25px;
  height: 25px;
  left: 90%;
  bottom: 30px;
  animation-delay: -10.5s;
  animation-duration: 19s;
  opacity: 0.3;
}


.shake-horizontal {
	-webkit-animation: shake-horizontal 40s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
	        animation: shake-horizontal 40s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}


@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}

/*
odd -left
even -right
*/
.activity_title {font-size: 16px;color: #00254B;text-align: left;font-weight: 600;}
body.rtl .activity_title {text-align: right;}
.chat-container {background: #FFFFFF;border: 1px solid #ccc;;border-radius: 10px;padding-top:10px;}
.chat-body {overflow-y: scroll;max-height: 50vh;padding: 15px;}
.chat-msg {margin-bottom: 15px;display: flex;    flex-direction: column;}
.chat-msg.message-odd{align-items: flex-end;margin-left:30px;}
body.rtl .chat-msg.message-odd{align-items: flex-start;margin-left:30px;}
.chat-msg.message-even{align-items: flex-start;margin-right:30px;}
body.rtl .chat-msg.message-even{ align-items: flex-end;margin-right:30px;}

.chat-msg .user_details{flex: 0 0 auto;color: #00254B;font-size: 12px;font-weight: 600;margin-bottom: 5px;display: flex;  align-items: center;}
.chat-msg.message-odd .user_details{  flex-direction: row-reverse;}
.chat-msg.message-odd .user_details .avatar{margin-left: 10px; margin-right: 0px;}
body.rtl .chat-msg.message-odd .user_details .avatar{margin-right: 10px; margin-left: 0px;}
.chat-msg.message-even .user_details .avatar{margin-right: 10px; margin-left: 0px;}
body.rtl .chat-msg.message-even .user_details .avatar{margin-left: 10px; margin-right: 0px;}
.chat-msg .message-content{flex: 0 0 auto; width:calc(100% - 30px);color: #00254B;font-size: 15px;font-weight: 500;line-height: 150%;padding:5px 10px;}
.chat-msg .message-date{flex: 0 0 auto; width:calc(100% - 30px);color: #696868;font-size: 10px;font-weight: 500;margin-top:5px}
.chat-msg .message-images{display: flex;flex-wrap: wrap;}
.chat-msg .message-images .image{padding:5px;flex: 0 0 auto;  width: 50%;}
.chat-msg .message-images .image img{max-width: 100%;height: 90px; object-fit: cover;  width: 100%;}

.chat-msg .message-content.message-odd{border-radius: 10px 0px 10px 10px;}
.chat-msg .message-content.message-even{border-radius: 0px 10px 10px 10px;}

.writeComment {display: flex; align-items: stretch; margin: 10px 10px;border-radius: 10px;padding: 5px 5px;justify-content: space-between;}
.writeComment .writeCommentBox{ border: 1px solid #B7B5B5;width: calc(100% - 40px );display: flex;    justify-content: space-around;    border-radius: 10px;}
.writeComment .comment{flex: 0 0 auto; width: 80%;max-height: 40px;background-color: transparent;}
.writeComment .comment textarea{padding-left: 10px;  }
.writeComment .comment {border: none!important;;}
.writeComment .image{flex: 0 0 auto; width: 10%;text-align: center;color:#1E96D4;display: flex;  align-items: center;  justify-content: center;    font-size: 20px;}
.writeComment .send{flex: 0 0 auto; width: 10%;text-align: center;color:#045F9B;display: flex;  align-items: center;  justify-content: center;    font-size: 20px;}
body.rtl .writeComment .send{transform: rotate(180deg);}
.writeComment label{display: flex;align-items: center;justify-content: center;}


.teamBox {border-radius: 10px; background: #045F9B;  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);color: #FFF;font-size: 16px;font-weight: 500;padding: 27px 27px;}


.productPageTitle {color: #00254B;font-size: 16px;font-weight: 600;}
.productForm *{color: #00254B !important;font-size: 16px !important;font-weight: 400 !important; opacity: 1 !important;border: none !important;}
.productForm  ion-label{font-weight: 500 !important;    max-width: max-content!important;}
.productForm ion-select::part(icon),.productForm.editModeEnabled ion-select::part(icon) {display: none !important; }
.productForm .bold .native-input{padding: 10px 0px;font-weight: 500 !important;}
.productForm ion-select , .productForm ion-input {margin-left: 5px;  }
body.rtl .productForm ion-select , body.rtl .productForm ion-input {margin-right: 5px;  }
productPageTitle img{margin-right: 10px;}
body.rtl .productPageTitle img{margin-left: 10px;}
small img{margin-left:25px; margin-right: 0;}
body.rtl small img{margin-right:25px;margin-left: 0;}


 .productForm.editModeEnabled ion-item{--border-width: 0 0 0px 0; margin-bottom: 10px;}
 /*.productForm.editModeEnabled ion-select , .productForm.editModeEnabled ion-input {border:1px solid  #B7B5B5!important;border-radius: 10px;  }*/
 .productForm.editModeEnabled ion-select,.productForm.editModeEnabled ion-input {width: 100%;}
 .productForm.editModeEnabled ion-item {border:1px solid  #B7B5B5!important;border-radius: 10px;    padding: 0 6px !important; }
 .productForm.editModeEnabled .native-input{padding: 0px;}
 .productForm.editModeEnabled .native-input {background-image: url("../icons/pencil-light.svg");background-repeat: no-repeat; background-position: right;    background-size: 16px;}
 body.rtl .productForm.editModeEnabled .native-input{ background-position: left;}

 .removeDynamicField {top:-5px;right:-5px}
 body.rtl .removeDynamicField {left:-5px;right: auto;}

 .productForm.editModeEnabled .dynamicFields .native-input{background-image: none;}

ion-button.dropbdown{width: 100%;--border-radius: 6px;--padding-start: 10px !important;  --padding-end: 10px !important;  --padding-top: 10px !important;  --padding-bottom: 10px !important;font-size: 16px;}



 .titleEdit {display: flex;justify-content: center; align-items: center;margin-top: 20px;}
  .titleEdit *{color: #00254B!important; font-size: 18px!important; font-weight: 600!important;  line-height: normal!important;}
 .titleEdit ion-input {max-width: max-content; border-radius: 6px; border: 1px solid #00254B;background: #fff;   margin-right: 16px;}
 .titleEdit ion-input input{padding:0!important;max-width: max-content;}

 .team_members ion-row {align-items: center;border-top:  solid 1px #B7B5B5}
 .team_members ion-row:last-child {border-bottom:  solid 1px #B7B5B5}


 .select-alert.single-select-alert, .select-alert.multiple-select-alert {--max-width: 85%;--min-width:85%;}
 /*.select-alert.single-select-alert *,.select-alert.multiple-select-alert * {color:#00254B!important;--color:#00254B!important;border-color: #00254B!important;}*/
 .alert-radio-group,.alert-button-group,.alert-button-group *,.alert-checkbox-group {border:none !important;}
.alert-button-group {align-items: center; justify-content:space-around!important;}

 .counter.badge { width: 50px;  height: 50px;  border: 1px solid #00254B;  border-radius: 100%;  display: flex;  align-items: center;  justify-content: center;  margin: auto;  color: #00254B;  background-color: #FFFFFF;  font-size: 15px;
  font-weight: 400;}


  .alert-button-group .alert-button{font-weight: bold;}
  .alert-button-group .alert-button.alert-button-role-cancel{font-weight: 300;}
  /*.alert-head:empty {display: none;}*/
  .alert-radio-group.sc-ion-alert-md, .alert-checkbox-group.sc-ion-alert-md {max-height: 80vh;}


  .popover-title {color: #00254B;font-size: 18px;font-weight: 600;}


  .teamInfo{ font-size: 16px;color: #000851;line-height: 1.6;}

  .show-modal{padding:10vh 5vw;--background: transparent;    /*--height: auto;--height: auto;*/--max-height: 110%;  --overflow: auto;}
  .alert-message {font-size: 16px;line-height: 1.3;padding-inline-end: 20px!important;}

  ion-item .input-wrapper {justify-content: space-between;}

  .project_edit {display: flex;align-items: center;justify-content: center;gap: 10px;}
  .project_edit ion-input{color: #00254B;max-width: max-content;border-radius: 6px; border: 1px solid #00254B;    background: #fff;    margin-right: 16px;}


  .custom_product_checkbox  .checkbox-icon{filter: drop-shadow(0px 4px 20px rgba(30, 150, 212, 0.25));width: 36px;height: 36px;}

  .custom_product_checkbox ion-checkbox {
    --size: 20px;
    --checkbox-background-checked: #00254B;
    --border-radius: 50%;
    --checkmark-width: 2;
    --background: white;
  }
  .fixed-button {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
  }
  body.rtl .fixed-button {left: auto;right: 50%;transform: translateX(50%);}
  .custom_product_checkbox ion-checkbox::part(container) {
    /*
    border-radius: 6px;
    border: 2px solid #00254B;
    filter: drop-shadow(0px 4px 20px rgba(30, 150, 212, 0.25));
    */
  }

  .ios .custom_product_checkbox {top:0px !important;}
  .modal-default ion-button.btn-clear{min-width: auto !important; margin-top: 7px;margin-right: 0;}


  .reports_forms *{visibility: visible;}
  .alert-wrapper{box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25) !important;border-radius: 10px!important;}





@media (min-width: 481px) {
  body{background: url(header-bg.png); background-repeat: repeat-x;  background-size: cover;}
  ion-app {max-width: 430px; margin: auto;position: relative; }
  ion-button, button, .leaflet-control a, .fix_accessibbility {min-width: auto !important;  min-height: auto !important;}
  .show-modal {padding: 1vh 1vw;}
  .leaflet-top .leaflet-control,.leaflet-top  a { width: 30px !important;  height: 30px!important;}
  .leaflet-top .leaflet-control {margin-top: 51px!important;}
  ion-button.btn-link{min-height: 45px!important;}
  ion-popover,.popover-viewport, ion-popover {--width: 220px; max-width: 100%;min-width:auto;/*left:0!important;left:0!important;--offset-x: 0px;*/}
  .scroll-content {
    overflow-y:auto;
  }
  .popover-content{left:0!important}
  body.rtl .popover-content{right:0!important}
  :root ion-popover::part(popover-content) {
    left: 0 !important;
  }
  body.rtl :root ion-popover::part(popover-content) {
    right: 0 !important;
  }
  ion-popover {
    &::part(content) {
      min-width: 230px;
      left: calc(100% - 235px) !important;
    }
  }
  body.rtl ion-popover {
    &::part(content) {
      left: 0 !important;
    }
  }
  .bottomTaps .tab * {color: #FFF!important;}
}



ion-radio {--border-radius: 0%;
  --border-width: 1px;
  --border-style: solid;
  --border-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.23);
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
  --size: 26px;
  width: var(--size);
  height: var(--size);
  border: 1px solid #ccc;
  margin: 0 10px 0 0px;
}


body.rtl ion-radio {margin: 0 0px 0 10px;}

.home_map_conrainer{position: absolute;width: 110px; height: 100%; top: 0; display: flex;align-items: center; flex-direction: column;  justify-content: center;}
body.rtl .home_map_conrainer{left: 0;}
